/**
 * Show/hide an element with the id "back-to-top", based on whether or not
 * some part of the header is showing.
 *
 * Showing/hiding is handled by adding/removing a 'btt-show' class, which
 * is styled in CSS
 *
 */
const BTT_SELECTOR = '#crh-back-to-top'; // CSS selector for the widget
const BTT_SHOW_CLASS = 'btt-show'; // Class that gets added to show the widget, removed to hide

const THRESHOLD = 0.1; // Show button when < 10% of header is visible

/**
 * Setup Back-To-Top functionality.
 *
 * Exported as a function so that Vue versions of BTT can import and call this after
 * they've mounted the appropriate element
 *
 */
export const setup = () => {
    const backToTop = document.querySelector<HTMLElement>(BTT_SELECTOR);
    const header = document.querySelector<HTMLElement>('header');

    if (backToTop && header) {
        backToTop.addEventListener(
            'click',
            () => {
                window.scrollTo(0, 0);
            }
        );

        // Handle changes to the header's visibility
        const callback: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio < THRESHOLD) {
                    backToTop.classList.add(BTT_SHOW_CLASS);
                } else {
                    backToTop.classList.remove(BTT_SHOW_CLASS);
                }
            });
        };

        // Setup a viewport observer that fires at our THRESHOLD
        //
        const observer = new IntersectionObserver(
            callback,
            {
                root: null,
                threshold: [THRESHOLD],
            }
        );

        // Watch the header intersecting with the viewport
        //
        observer.observe(header);
    }
};

setup();
